import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class MatomoService {
  public init(id, url) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    const paq = 'var _paq=window._paq=window._paq||[];_paq.push(["trackPageView"]);_paq.push(["enableLinkTracking"]);';
    /* tslint:disable */
    const method =  '(function(){var u="' + url + '";_paq.push(["setTrackerUrl",u+"matomo.php"]);_paq.push(["setSiteId",' + id + ']); _paq.push(["addTracker","' + environment.PIWIK_URL + 'piwik.php'+ '",' + environment.PIWIK_SITE_ID + ']); var d=document,g=d.createElement("script"),s=d.getElementsByTagName("script")[0];g.async=true;g.src=u+"matomo.js";s.parentNode.insertBefore(g,s);})();';    /* tslint:enable */
    script.text = paq + method;
    document.body.appendChild(script);
  }
}
