import { Injectable } from '@angular/core';

@Injectable()
export class PiwikService {
  public init(id, url) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    const paq = 'var _paq=_paq||[];_paq.push(["trackPageView"]),_paq.push(["enableLinkTracking"]);';
    /* tslint:disable */
    const method = '!function(){var e="' + url + '";_paq.push(["setTrackerUrl",e+"piwik.php"]),_paq.push(["setSiteId",' + id + ']);var t=document,r=t.createElement("script"),p=t.getElementsByTagName("script")[0];r.type="text/javascript",r.async=!0,r.defer=!0,r.src=e+"piwik.js",p.parentNode.insertBefore(r,p)}();';
    /* tslint:enable */
    script.text = paq + method;

    document.body.appendChild(script);
  }
}
